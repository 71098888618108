<template>
  <b-card
    no-body
    class="p-3"
    >
    <b-row align-h="center">
      <b-col
        sm="12"
        md="8"
        >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('apps.masterDataUser.singular.code')"
                  label-for="code"
                  >
                  <b-form-input
                    id="category-code"
                    size="sm"
                    v-model="form.code"
                    :placeholder="$t('apps.masterDataUser.placeholder.code')"
                    :disabled="actions.isPreview"
                    />
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  :label="$t('apps.masterDataUser.singular.name')"
                  label-for="name"
                  >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                    >
                    <b-form-input
                      id="category-name"
                      size="sm"
                      v-model="form.name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataUser.placeholder.name') : null"
                      :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <b-form-group :label="$t('apps.masterDataUser.singular.companyMail')">
                  <validation-provider
                    #default="{ errors }"
                    name="Company Mail"
                    rules="email"
                    >
                    <b-form-input
                      id="mail"
                      size="sm"
                      v-model="form.email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="user@mail.com"
                      :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col md="4">
                <b-form-group :label="$t('globalSingular.office')" label-for="officeId">
                  <validation-provider
                    #default="{ errors }"
                    name="officeId"
                    >
                    <v-select
                      id="officeId"
                      v-model="form.officeId"
                      :options="LOV.offices"
                      :reduce="field => field.id"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                      />
                      <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$t('globalSingular.role')" label-for="roleId">
                  <validation-provider
                    #default="{ errors }"
                    name="roleId"
                    >
                    <v-select
                      id="roleId"
                      v-model="form.roleId"
                      :options="LOV.roles"
                      :reduce="field => field.id"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                      />
                      <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('globalSingular.status')"
                  label-for="status">
                  <v-select
                    id="status"
                    v-model="form.status"
                    :options="LOV.status"
                    :reduce="field => field.code"
                    label="label"
                    :disabled="actions.isPreview"
                    class="select-size-sm"
                    :placeholder="!actions.isPreview ? 'Active'  : null"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('apps.masterDataUser.singular.isNational')"
                  label-for="isNational">
                  <v-select
                    id="isNational"
                    v-model="form.isNational"
                    :options="LOV.isNational"
                    :reduce="field => field.code"
                    label="label"
                    :disabled="actions.isPreview"
                    class="select-size-sm"
                    :placeholder="!actions.isPreview ? 'True' : null"
                    />
                </b-form-group>
              </b-col>
            </b-row>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-master-data-user-list' }"
                >
                <feather-icon
                  icon="ArrowLeftIcon"
                  />
                  {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
                >
                <feather-icon
                  icon="Trash2Icon"
                  />
                  {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
                >
                <feather-icon
                  icon="SaveIcon"
                  />
                  {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
                >
                <feather-icon
                  icon="EditIcon"
                  />
                  {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
                </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  // BFormTextarea,
  BButton
} from 'bootstrap-vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BForm,
    // BFormTextarea,
    BButton,
    // BFormText,
    // BFormDatalist,
    ValidationProvider,
    ValidationObserver
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: '/master/user', localeContextPath: 'apps.masterDataUser.moduleName', redirectPathName: 'apps-master-data-user' })

    const LOV = ref({
      offices: [],
      roles: [],
      status: [
        {
          label: 'Active',
          code: 'TRUE'
        },
        {
          label: 'Non Active',
          code: 'FALSE'
        }
      ],
      isNational: [
        {
          label: 'True',
          code: 'TRUE'
        },
        {
          label: 'False',
          code: 'FALSE'
        }
      ]
    })

    const form = ref({
      code: '',
      name: '',
      email: '',
      officeId: null,
      roleId: null,
      status: null,
      isNational: null
    })

    // Get roles
    const getRole = async () => {
      LOV.value.roles = await get({ url: 'setting/roles' })
    }

    // Get offices
    const getOffice = async () => {
      LOV.value.offices = await get({ url: 'master/offices'})
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.code = data[0].user.user_code
      form.value.name = data[0].user.user_name
      form.value.email = data[0].user.user_email
      form.value.officeId = data[0].office_id
      form.value.roleId = data[0].role_id
      form.value.status = data[0].is_active ? 'TRUE' : 'FALSE'
      form.value.isNational = data[0].is_national ? 'TRUE' : 'FALSE'
    }

    onMounted(() => {
      getRole()
      getOffice()
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
    })

    return {
      LOV,
      required,
      form,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
